import React from "react";
import croppedLogo from "../../assets/logo cropped.png";
export const NavMenuButton = ({ toggleMenu, navOpen }) => {
  return (
    <button
      type="button"
      onClick={toggleMenu}
      className="group absolute -inset-y-1/2 -top-1/2 right-6  flex items-center"
    >
      <span className="sr-only">Open main menu</span>
      <div className="flex flex-col items-end justify-start sm:hidden">
        <div className="burger_line mb-1 h-1 w-7 bg-black transition-all duration-300 group-hover:bg-secondary-100  group-active:bg-secondary-100 md:w-9  xl:mb-1.5 xl:h-1.5 xl:w-12"></div>
        <div className="burger_line mb-1 h-1  w-5 bg-black  transition-all  duration-300 group-hover:bg-secondary-100 group-active:bg-secondary-100 md:w-7 xl:mb-1.5 xl:h-1.5  xl:w-9"></div>
        <div className="burger_line mb-0 h-1 w-3 bg-black transition-all  duration-300  group-hover:bg-secondary-100 group-active:bg-secondary-100 md:w-5 xl:mb-1.5 xl:h-1.5  xl:w-6"></div>
      </div>
      <img
        src={croppedLogo}
        alt="ABI logo"
        className="hidden w-12 sm:block xl:w-16 2xl:w-20"
        loading={"lazy"}
      />
      <span
        className="opacity-1 ml-2 hidden text-xl font-bold
      tracking-wider  text-black transition-all  
      duration-300  group-hover:text-secondary-100 
      group-active:text-secondary-100 sm:block  md:text-xl
      xl:ml-3 xl:text-2xl
      2xl:text-3xl
      "
      >
        MENU
      </span>
    </button>
  );
};
