import { Category } from "./Category";
import mouldingIcon from "../../assets/products/mouldingIcon.webp";
import doorIcon from "../../assets/products/doorIcon.webp";
import calculatorIcon from "../../assets/products/calculatorIcon.webp";
import databaseIcon from "../../assets/products/databaseIcon.webp";
import customOrders from "../../assets/products/customOrders.webp";
import findOurProduct from "../../assets/products/find.webp";
import { ModalCategory } from "./ModalCategory";

const categories = [
  {
    id: "1d",
    header: "Mouldings",
    paragraph: `With our dedication to craftsmanship and attention to detail, we provide a diverse range of mouldings.`,
    icon: mouldingIcon,
    path: "/mouldings",
  },
  {
    id: "2d",
    header: "MDF Interior Doors ",
    paragraph: `Flawless finish and superior structural integrity. Our doors are built to last, providing long-term durability and functionality.`,
    icon: doorIcon,
    path: "/doors",
  },
  {
    id: "3d",
    header: "Custom Orders",
    paragraph: `Whether you're an architect, designer, contractor, or a homeowner, we are here to turn your ideas into reality.`,
    icon: customOrders,
    path: "/custom",
  },
  {
    id: "4d",
    header: "Database",
    paragraph: `Browse, filter and download moulding profiles - all in one place.`,
    icon: databaseIcon,
    path: "/database",
  },
  {
    id: "5d",
    header: "Purchase Our Products",
    paragraph: `Our authorized dealers are conveniently located in various locations, ensuring that you can find our products near you.`,
    icon: findOurProduct,
    path: "/dealers",
  },
  {
    id: "6d",
    header: "Shiplap Calculator",
    paragraph: `An interactive tool that helps you calculate the quantity of shiplap you need to cover a certain amount of square footage.`,
    icon: calculatorIcon,
    path: "/calculator",
  },
];

export const FeatureCategories = () => {
  return (
    <div className="container  mx-auto my-8 sm:my-16 ">
      <div className="mx-6">
        <h4 className="mb-4 text-xs font-semibold uppercase sm:text-sm">
          What we do
        </h4>
        <h2 className="mb-8 text-xl  font-bold tracking-wider sm:text-2xl md:mb-14 lg:w-4/5 lg:text-3xl 2xl:text-4xl">
          We specialize in manufacturing high-quality MDF mouldings and doors.
        </h2>
        <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-10 lg:grid-cols-3">
          {categories.map((category) =>
            category.path !== "/custom" && category.path !== "/delivery" ? (
              <Category values={category} key={category.id} />
            ) : (
              <ModalCategory values={category} key={category.id} />
            )
          )}
        </div>
      </div>
    </div>
  );
};
